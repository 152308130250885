import { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import dayjs from "dayjs"
import { PodClass } from "../../../classes/Pod"
import { Alert, Box, Button, Checkbox, CircularProgress, Divider, FormControlLabel, Switch } from "@mui/material"
import { useStore } from '../../../contexts/store'
import { useParams } from "../../../helper/Helper"
import { PodInformation, Usergroup } from "../../../../../types/Pod"
import { useTranslation } from "react-i18next"
import { green } from '@mui/material/colors';
import { OpCode } from "../../../../../types/OpCodes"
import React from "react"
import { Op_editPermission, Op_editPod } from "../../../../../types/Ops"
import { downloadableType } from "../../../../../types/Miscs"
import Prompt from "../../Elements/Prompt"
import { podKeyphraseMinLength, podKeyphraseMaxLength } from "../../../validationConstantsString"
import api from "../../../api/api"


const Permissions = () => {
  const { podId } = useParams()
  const { podStore, opStore, sessionStore } = useStore()
  const isAdmin:boolean = podStore.pod?.isAllowed('deletePod', podStore.pod?.podId) ? true : false

  const [showPodKeyphrasePrompt, setShowPodKeyphrasePrompt] = useState<boolean>(false)
  const [showReduced, setShowReduced] = React.useState<boolean>(!isAdmin)
  const [ podInfo, setPodInfo ] = useState<null|PodInformation|'failed'|'loading'>(null)

  const { t } = useTranslation()
  const session = sessionStore.session

  const getPodInfo = async(podId:string) => {
    setPodInfo('loading')
    const res = await api.getPodInformation(podId)
    if (res) {
      setPodInfo(res)
    } else {
      setPodInfo('failed')
    }
  }

  useEffect(() => {
    if(podInfo !== 'loading') getPodInfo(podId)
  }, [])


  const pod:PodClass|null = podStore.pod
  if (!pod) return null

  const editPermission = (usergroupId: string, permission:OpCode, granted: boolean) => {
    if (!pod.permissions['editPermission']) return null
    const op:Op_editPermission = {
      op: 'editPermission',
      podId: pod.podId,
      data: {
        userId: session.user.userId,
        userName: podStore.userPseudonym || '',
        usergroupId,
        permission,
        granted,
      }
    }
    opStore.doOp(op)
  }

  const perm = (group:Usergroup, opCode:string) => {
    const disabled = (!pod.permissions['editPermission']) || (opCode === 'editPermission')
    const style = {
      cursor: (disabled) ? 'default' : 'pointer'
    }

    return <div key={opCode}><FormControlLabel style={style}
    label={t(opCode)}
    control={<Checkbox
      disabled={disabled}
      checked={group.permissions.indexOf(opCode)>-1}
      sx={{padding:0,'&.Mui-checked': {color: green[600]}}}
      onChange={(e) => { editPermission(group.usergroupId, opCode as OpCode, e.target.checked) }}
      />
    }/>
    </div>
  }

  const permissionSet = (name:string, group:Usergroup, perms:OpCode[]) => {
    const permissions = perms.map(p => perm(group, p))
    if (permissions.filter(p => p !== null).length) return <div>
      {name}
      <Box style={{paddingLeft:10, paddingBottom:10}}>
        {permissions}
      </Box>
    </div>

    return null
  }

  const permissionsGroup = (name:string, group:Usergroup, sets:any) => {
    if (sets.filter((s:any) => s !== null).length) return <>
      <h4>{name}</h4>
      {sets.map((s:any, i:number) => <div key={i}>{s}</div>)}
    </>

    return null
  }

  const updatePodInfo = () => {
    setPodInfo('loading')
    setTimeout(() => {
      getPodInfo(podId)
    }, 6000)
  }

  const deletePodKeyphase = () => {
    const op:Op_editPod = {
      op: 'editPod',
      podId: pod.podId,
      data: {
        mods: {
          keyphrase: null,
          tModified: dayjs().unix()
        },
        userId: session.user.userId,
        usergroupId: pod.getUsergroupByRole('Pod').usergroupId,
        userName: podStore.userPseudonym || '',
      }
    }
    opStore.doOp(op)
    updatePodInfo()
  }

  const changePodSetting = (which:'name'|'description'|'keyphrase', s:string) => {
    if (s) {
      const mods: {
        name?: string,
        description?: string,
        allowDownload?:downloadableType[],
        keyphrase?: string,
        tModified?: number
      } = {
        tModified: dayjs().unix()
      }
      mods[which] = s

      const op:Op_editPod = {
        op: 'editPod',
        podId: pod.podId,
        data: {
          mods,
          userId: session.user.userId,
          usergroupId: pod.getUsergroupByRole('Pod').usergroupId,
          userName: podStore.userPseudonym || '',
        }
      }

      opStore.doOp(op)
      // update if pod has no keyphrase and new one is set
      if(which === 'keyphrase' && podInfo && typeof podInfo === 'object' && podInfo.hasKeyphrase === false) {
        updatePodInfo()
      }
    }
  }

  const setAllowDownload = (which:downloadableType, value:boolean) => {
    const allowDownload = pod.allowDownload.filter(s => s !== which)
    if (value) allowDownload.push(which)
    const op:Op_editPod = {
      op: 'editPod',
      podId: pod.podId,
      data: {
        mods: {
          tModified: dayjs().unix(),
          allowDownload
        },
        userId: session.user.userId,
        usergroupId: pod.getUsergroupByRole('Pod').usergroupId,
        userName: podStore.userPseudonym || '',
      }
    }
    opStore.doOp(op)
  }

  const KeyphraseSettings = observer(({podInfo}: {podInfo: null|PodInformation|'failed'|'loading'}) => {
    if(podInfo === null) return null
    if(podInfo === 'failed') return (
      <Alert sx={{m: 1}} severity="error">{t('Loading did not work, check your internet connection and try again')}</Alert>
    )
    if(podInfo === 'loading') return (
      <CircularProgress sx={{marginLeft: "20px"}} size="25px" />
    )

    if(podInfo && typeof podInfo === 'object' && podInfo.hasKeyphrase) {
      return (
        <Box sx={{mt:1, mb:1, display: "flex", flexWrap: "wrap", gap: "10px"}}>
          <Button variant='outlined' disabled={!pod.permissions['editPod']} onClick={() => {setShowPodKeyphrasePrompt(true)}}>{t('Change Pod Keyphrase')}</Button>
          <Button color="error" variant='outlined' disabled={!pod.permissions['editPod']} onClick={() => {deletePodKeyphase()}}>{t('Delete Pod Keyphrase')}</Button>
        </Box>
      )
    }
    if(podInfo && typeof podInfo === 'object' && !podInfo.hasKeyphrase) {
      return (
        <Box sx={{mt:1, mb:1}}>
          <Button variant='outlined' disabled={!pod.permissions['editPod']} onClick={() => {setShowPodKeyphrasePrompt(true)}}>{t('Provide pod access with password')}</Button>
        </Box>
      )
    }

    return null
  })

  return  <Box>
            <h2>{t('Keyphrase')}</h2>
            <Box sx={{mt:1, mb:1, minHeight: "40px"}}>
              <KeyphraseSettings podInfo={podInfo} />
            </Box>
            <Divider />
            <h2>{t('Allow Downloads')}</h2>
            <Box>
              <div onClick={() => { if (pod.permissions['editPermission']) setAllowDownload('pdf', pod.allowDownload.indexOf('pdf')===-1) }} style={{cursor: pod.permissions['editPermission'] ? 'pointer' : 'default'}}>
              <Checkbox disabled={!pod.permissions['editPermission']} checked={pod.allowDownload.indexOf('pdf')>-1} sx={{padding:0}} /> {t('Allow users to download this Pod\'s PDF files')}
              </div>
              { ['annotation','comment','emotion','link','readingQuestion','tagging','weblink'].map(option => {
              const disabled = (!pod.permissions['editPermission']) || (pod.allowDownload.indexOf('pdf')===-1)
              const checked  = (pod.allowDownload.indexOf(option)>-1) && (pod.allowDownload.indexOf('pdf')>-1)

              return <div key={option} onClick={() => { if (!disabled) setAllowDownload(option, pod.allowDownload.indexOf(option)===-1) }} style={{cursor: disabled ? 'default' : 'pointer'}}>
                <Checkbox disabled={disabled} checked={checked} sx={{padding:0}} /> {t("Allow users to include '{{option}}' interactions", {option: t(option)})}
              </div>
              }) }
            </Box>

            <h2>{t('Permissions')}</h2>
            <FormControlLabel control={<Switch checked={showReduced} onChange={() => setShowReduced(!showReduced)} />} label={t('Show only permissions I have')} />
            {Object.keys(pod.usergroups).map((usergroupId:string) => {
              const group:Usergroup = pod.usergroups[usergroupId]

              return <Box key={usergroupId} style={{clear:'both'}}>
                <h3>{t('Via Group "{{groupname}}"', {groupname: group.name})}</h3>
                {
                permissionsGroup('Pod Administration', group, [
                  permissionSet('Folder', group, ['addFolder', 'editFolder', 'deleteFolder']),
                  permissionSet('Files:', group, ['addPdfFile', 'editPdfFile', 'deletePdfFile']),
                  permissionSet('Users:', group, ['addUserToPod', 'removeUserFromPod', 'editUserInfo', 'editPod', 'editPermission'])
                ])
                }
                {
                permissionsGroup('Pod Collaboration', group, [
                  permissionSet('Tags:', group, ['addTag', 'editTag', 'deleteTag']),
                  permissionSet('Conversations:', group, ['addThread', 'addMessage', 'editMessage', 'deleteMessage']),
                ])
                }
                {
                permissionsGroup('Interactions', group, [
                  permissionSet('Annotations:', group, ['addAnnotation', 'editAnnotation', 'deleteAnnotation']),
                  permissionSet('Comments:', group, ['addComment', 'editComment', 'deleteComment']),
                  permissionSet('Emotions:', group, ['addEmotion', 'editEmotion', 'deleteEmotion']),
                  permissionSet('Links:', group, ['addLink', 'editLink', 'deleteLink']),
                  permissionSet('Reading Questions:', group, ['addReadingQuestion', 'editReadingQuestion', 'deleteReadingQuestion']),
                  permissionSet('Taggings:', group, ['addTagging', 'editTagging', 'deleteTagging']),
                  permissionSet('Weblinks:', group, ['addWeblink', 'editWeblink', 'deleteWeblink']),
                ])
                }
              </Box>
            })}

            <Prompt title={t('Pod Keyphrase')} info={t('Please enter a new keyphrase for this pod') as string} open={showPodKeyphrasePrompt} handleClose={() => { setShowPodKeyphrasePrompt(false) }} onOk={(s:string) => { changePodSetting('keyphrase', s) }} type={{type:'string', minLength: podKeyphraseMinLength, maxLength:podKeyphraseMaxLength}} />

    </Box>
  }

export default observer(Permissions)