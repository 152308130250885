import React, { createRef, useEffect } from 'react'
// components
import { baseUrl } from '../../api/api'
import Thumbnails from '../Pdf/Thumbnails/Thumbnails'
import Viewer from '../Pdf/Viewer'
// mui
import { useStore } from '../../contexts/store'
import { observer } from 'mobx-react-lite'
import ErrorBoundaryComponent from '../ErrorBoundaryComponent'
import InteractionDrawer from '../Pdf/InteractionDrawer/InteractionDrawer'
import { Box } from '@mui/material'
import TransformInteractionMenu from '../Elements/TransformInteractionMenu'
import OverlappingInteractionsMenu from '../Elements/OverlappingInteractionsMenu'
import PdfFooter from '../Pdf/PdfFooter'
import uiStore from '../../stores/uiStore'

const Pdf = ({pdfId}: {pdfId:string|undefined}) => {
  const { podStore, sessionStore, pdfMetaStore } = useStore()
  const pod = podStore.pod
  const pdfUrl = `${baseUrl}/pdf/${pdfId}`
  const thumbnailUrl = `${baseUrl}/pageView/${pdfId}`
  const pageListRef: any = createRef()
  const thumbListRef: any = createRef()

  useEffect(() => {
    const storeId = pdfUrl

    // only use this function to detect pinch zoom and its change
    const onTouchTargetMove = (e: any) => {
      const scale = pdfMetaStore.getScale(storeId)

      const numberOfTouches = Object.keys(e.touches).length
      if(numberOfTouches === 2) {
        ;[...e.touches].forEach((touch: Touch) => {
          // uiStore.addToTouchList(touch.identifier, touch.pageX, touch.pageY)
        })
        pdfMetaStore.setScale(storeId, scale - 0.04)
        // const dist = Math.hypot(
        //   touches[0].lastX - touches[1].pageX,
        //   touches[0].lastY - touches[1].pageY
        // )
      }
    }

    const onTouchStart = (e: TouchEvent) => {
      const numberOfTouches = Object.keys(e.touches).length
      const eventTarget: EventTarget | null = e.target
      if(eventTarget) {
        if(numberOfTouches === 2) {
          // prevent device gesture recognition for pinch zoom
          e.preventDefault()
          // add dom change persistent touch move listener on event target of touch start
          eventTarget.addEventListener("touchmove",(event) => onTouchTargetMove(event))
          // remove move listener if pinch zoom is finished
          // use once attribute to save the trouble of cancelling the end listener
          eventTarget.addEventListener("touchend", () => {
            eventTarget.removeEventListener("touchmove", (event) => onTouchTargetMove(event))
            uiStore.clearTouchList()
          }, { once: true })
        }
      }
    }
    const onTouchMove = (e: TouchEvent) => {
    }
    const onTouchEnd = (e: TouchEvent) => {
    }
    const onTouchCancel = (e: TouchEvent) => {
      onTouchEnd(e)
    }

    // init touch event listener
    const touchElement = document.getElementById(`${storeId}-pdf-viewer-container`)
    if(touchElement) {
      touchElement.addEventListener("touchstart", onTouchStart)
      touchElement.addEventListener("touchmove", onTouchMove)
      touchElement.addEventListener("touchend", onTouchEnd)
      touchElement.addEventListener("touchcancel", onTouchCancel)
    }

    // on unmount: remove touch event listener
    return () => {
      const touchElement = document.getElementById(`${storeId}-pdf-viewer-container`)
      if(touchElement) {
        touchElement.removeEventListener('touchstart', onTouchStart)
        touchElement.removeEventListener("touchmove", onTouchMove)
        touchElement.removeEventListener("touchend", onTouchEnd)
        touchElement.removeEventListener("touchcancel", onTouchCancel)
      }
    }
  })



  if (!pdfId) return  <div>Could not render the pdf file. PdfId missing.</div>
  if (!pod) return <div>Could not render the pdf file "{pdfId}". Pod missing.</div>

  const podId = pod.podId
  const file = pod.content.pdfFiles[pdfId]
  if (!file) return <div>Could not render the pdf file. No Pdf file found.</div>

  if (pod.nodeIsHidden(pdfId) && file.userId !== sessionStore.session.user.userId) return <div>Could not render the pdf file (1).</div>
  if (!pod.isVisible('pdfFile', pdfId)) return <div>Could not render the pdf file (2).</div>

  return <ErrorBoundaryComponent>
    <Box sx={{display: "grid", gridTemplateRows: "auto min-content", overflowY: "auto"}}>
      <Box id={`${pdfUrl}-pdf-viewer-container`} sx={{
        backgroundColor: "whitesmoke",
        display: "grid",
        gridTemplateColumns: uiStore.deviceWidth === "tiny" ? "auto" : `auto ${uiStore.getThumbContainerWidth()}px`,
        overflowY: "auto",
      }}>
        <ErrorBoundaryComponent context="Pdf>Viewer">
          <Viewer
            pageListRef={pageListRef}
            pdfId={pdfId}
            pdfUrl={pdfUrl}
            podId={podId}
            storeId={pdfUrl}
            thumbListRef={thumbListRef}
            thumbnailUrl={thumbnailUrl}
          />
        </ErrorBoundaryComponent>
        <Thumbnails
          pageListRef={pageListRef}
          storeId={pdfUrl}
          thumbListRef={thumbListRef}
          thumbnailUrl={thumbnailUrl}
        />
        <InteractionDrawer
          pageListRef={pageListRef}
          storeId={pdfUrl}
        />
        <TransformInteractionMenu />
        <OverlappingInteractionsMenu podId={podId} pdfId={pdfId} />
      </Box>
      <PdfFooter storeId={pdfUrl} />
    </Box>
  </ErrorBoundaryComponent>
}

export default observer(Pdf)
