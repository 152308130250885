import { t } from "i18next"
import { makeObservable, action, observable } from "mobx"
import alertStore, { alert } from '../stores/alertStore'

import { Interaction, InteractionType, interactionAnchor, InteractionToolLiteral, Rect } from '../../../types/Interaction'
import { OpCode } from "../../../types/OpCodes"

import { useLocation } from 'wouter'
import { DeviceWidths, PodScrollPos } from '../../../types/Miscs'
import { convertNames } from "../helper/Helper"
import { notInstantLoginRegex, usernameMaxLength, notUserNameRegex } from "../validationConstantsString"
import podStore from "./podStore"
import { navigate } from "wouter/use-location"

export type SelectionRange = { node:Node, offset:number, t0:number, pageNumber:number, clientX: number, clientY: number }
export type freehandBox = { page: number, x0: number, y0:number, x1:number, y1:number }
export type ActivePointerType = {storeId: string, pointerType: 'mouse'|'pen'|'touch' , lastX: number, lastY: number, rectArray: Rect[]}
export type VisibilitySelectable = InteractionType | 'showUnseen'
export type ToolRepresentation = 'block' | 'underline' | 'none'

export const useParams = () => {
  const [ location ] = useLocation()
  let parms:RegExpMatchArray | null = null
  let urlParams:{[key:string]:string} = {}

  parms = location.match(/^\/pod\/([^/]+)\/?(pdf\/([^/]+))?\/?(([a-zA-Z]+)\/([^/]+))?/)
  if (parms && parms[1]) urlParams.podId  = parms[1]
  if (parms && parms[3]) urlParams.nodeId = urlParams.pdfId = parms[3]      // best practice: use nodeId, but we will provide pdfId for legacy reasons
  if (parms && parms[5]) urlParams.interactionType = parms[5]
  if (parms && parms[6]) urlParams.interactionId = parms[6]

  return urlParams
}

export interface uiStoreModel {
    isOffline: boolean,
    readingTimer: number,
    selectedAnchor: interactionAnchor | null,
    annotationColors: string[],
    previewColor: {interactionId: string, color: string} | null,
    setPreviewColor: (interactionId: string, color: string) => void,
    deletePreviewColor: () => void,
    getPreviewLinkLabel: (interactionId: string) => string | null
    setPreviewLinkLabel: (interactionId: string, linkLabel: string) => void,
    deletePreviewLinkLabel: (interactionId: string) => void,
    selectedLinkAnchor: interactionAnchor | null,
    showVerboseLogging: {[feature:string]: boolean},
    interactionModal: {isOpen: boolean, interactionId: string | null, isTransformation: boolean | null},
    openInteractionModal: (interactionId: string, isTransformation: boolean) => void,
    closeInteractionModal: () => void,
    addUserModalIsOpen: boolean,
    openAddUserModal: () => void,
    closeAddUserModal: () => void,
    transformInteractionMenu: {isOpen: boolean, interactionId: string | null, anchor: any},
    openTransformInteractionMenu: (interactionId: string, anchor: any) => void,
    closeTransformInteractionMenu: () => void,
    overlappingInteractionMenu: {isOpen: boolean, interactionList: {interaction: Interaction, menuAnchor: any}[] | null},
    openOverlappingInteractionMenu: (interactionList: {interaction: Interaction, menuAnchor: any}[]) => void,
    closeOverlappingInteractionMenu: () => void,
    selectInteraction: (selected: {interaction:  Interaction, menuAnchor: any}, podId: string, pdfId: string) => void
    getSelectedOperation: () => OpCode | null,
    getSemiTransparentColor: (color: string) => string,
    getAnnotationColor: () => string,
    setSelectedAnchor: (anchor: interactionAnchor | null) => void,
    setSelectedLinkAnchor: (anchor: interactionAnchor) => void,
    deleteSelectedLinkAnchor: () => void,
    setSelectedOperation: (interactionType: OpCode | null) => void,
    setAnnotationColor: (color: string) => void,
    setIsOffline(isOffline: boolean): void,
    getInteractionColor: (op: OpCode | InteractionType) => string
    getToolRepresentation: (interactionType: InteractionType, tool: InteractionToolLiteral) => ToolRepresentation
    deviceWidth: DeviceWidths
    pageMargin: number | undefined
    setDeviceWidth: (width: number) => void
    width: number
    cookieConsent: boolean
    setCookieConsent: (consent: boolean) => void

    selectionStartRange: SelectionRange | null
    selectionEndRange: SelectionRange | null
    setSelectionStartRange: (range: SelectionRange|null) => void
    setSelectionEndRange: (range: SelectionRange|null) => void
    isPinchZooming: boolean
    setIsPinchZooming: (isPinchZooming: boolean) => void
    marginaliaIconRadius: number

    urlParams: {[key:string]: string}
    setUrlParams: (key:string, value:string) => void

    folderIsOpen: {[podId:string]: {[folderId: string]: boolean}}
    setFolderIsOpen: (podId:string, folderId:string, status:boolean) => void

    editedMessageId: string | null
    setEditedMessageId: (messageId: string | null) => void

    forceUpdate: number
    forceViewerUpdate: () => void

    getThumbContainerWidth: () => number

    getPodScrollPos: (podId: string, tab: string) => number | null
    setPodScrollPos: (podId: string, tab: string, pos: number) => void
    getActiveConversation: (podId: string) => string | null
    setActiveConversation: (podId: string, activeConversation: string | null) => void

    timer: number
    incrementTimer: () => void

    getActivePodTab: (podId: string) => string
    setActivePodTab: (podId: string, activeTab: string) => void

    isFreehandSelection: boolean
    setIsFreehandSelection: (isFreehandSelection: boolean) => void
    freehandBox: freehandBox | null
    setFreehandBox: (page?: number|null, x0?: number|null, y0?: number|null, x1?: number|null, y1?: number|null) => void

    hideInteractions: {[interactionType:string]: boolean }
    setHideInteraction: (interaction: VisibilitySelectable, value:boolean) => void

    restrictString: (s:string, type?:'name'|'filename'|'foldername'|'tag'|'username', length?:number|null) => string

    instantUserPad: string
    updateInstantUserPad: (userPad: string) => void
    instantUserList: {id: number, login: string, password: string, username: string}[] | []
    generateInstantUserList: (userList: string[]) => void
    updateInstantUserPassword: (id: number, password: string) => void
    deleteInstantUserList: () => void

    showUnreads: boolean
    setShowUnreads: (showUnreads: boolean) => void

    sidebarStatus: string
    setSidebarStatus: (sidebarStatus: string) => void
    activeSidebarTags: string[]
    toggleActiveSidebarTag: (tag: string) => void
    clearActiveSidebarTags: () => void

    pointerDownStart: number | null,
    setPointerDownStart: (pointerDownStart: number | null) => void
    activePointer: {[pointerId: number]: ActivePointerType}
    addActivePointer: (pointerId: number, pointerType: 'mouse'|'pen'|'touch', storeId: string, x: number, y: number) => void
    updateActivePointerPosition: (pointerId: number, x: number, y: number) => void
    addActivePointerRect: (pointerId: number, react: Rect) => void
    removeActivePointer: (pointerId: number) => void

    touchList: {[touchId: number]: {x: number, y: number}}
    addToTouchList: (touchId: number, x: number, y: number) => void
    removeFromTouchList: (touchId: number) => void
    clearTouchList: () => void
  }

class uiStore {
  // default ui settings
  isOffline = false
  readingTimer = 20
  annotationColors = ["#DC267F", "#14ee73", "#FFB000", "#4bdae8", "#FE6100"]
  previewColor: {color: string, interactionId: string} | null = null
  previewLinkLabel: {[interactionId: string]: string} = {}
  selectedAnnotationColor = "#DC267F"
  selectedAnchor: interactionAnchor | null = null
  selectedLinkAnchor:interactionAnchor | null = null
  interactionModal: {isOpen: boolean, interactionId: string | null, isTransformation: boolean | null} = {isOpen: false, interactionId: null, isTransformation: null}
  addUserModalIsOpen: boolean = false
  transformInteractionMenu: {isOpen: boolean, interactionId: string | null, anchor: any} = {isOpen: false, interactionId: null, anchor: null}
  overlappingInteractionMenu: {isOpen: boolean, interactionList: {interaction: Interaction, menuAnchor: any}[] | null} = {isOpen: false, interactionList: null}
  selectedInteractionType: OpCode | null = null
  showVerboseLogging = {
    sync: false,
    loadPod: false,
    opProcessing: false,
    view: false,
  }
  overlayColorAlpha = 0.5
  urlParams: {[key:string]: string} = {}
  width = 0
  deviceWidth: DeviceWidths = undefined
  pageMargin: number | undefined = undefined
  cookieConsent = false
  selectionStartRange: SelectionRange | null = null
  selectionEndRange: SelectionRange | null = null
  isPinchZooming: boolean = false
  marginaliaIconRadius = 20

  folderIsOpen: {[podId:string]: {[folderId: string]: boolean}} = {}
  editedMessageId: string | null = null
  forceUpdate: number = 0

  podScrollPos: PodScrollPos[] = []
  activeConversations: {[podId:string]: string | null} = {}

  timer: number = 1
  activePodTab: {[podId:string]: string} = {}

  isFreehandSelection = false
  freehandBox:freehandBox|null = null
  hideInteractions:{[interactionType:string]: boolean } = {}

  instantUserPad: string = ''
  instantUserList: {id: number, login: string, password: string, username: string}[] | [] = []

  showUnreads = false
  sidebarStatus = 'closed'
  activeSidebarTags: string[] = []

  pointerDownStart: number | null = null
  activePointer: {[pointerId: number]: ActivePointerType} = {}

  touchList: {[touchId: number]: {x: number, y: number}} = {}

  constructor() {
    makeObservable(this, {
      isOffline: observable,
      getSelectedOperation: observable,
      getSemiTransparentColor: action,
      getAnnotationColor: observable,
      getToolRepresentation: action,
      selectedAnchor: observable,
      selectedLinkAnchor: observable,
      annotationColors: observable,
      previewColor: observable,
      setPreviewColor: action,
      deletePreviewColor: action,
      getPreviewLinkLabel: observable,
      setPreviewLinkLabel: action,
      deletePreviewLinkLabel: action,
      selectedAnnotationColor: observable,
      selectedInteractionType: observable,
      showVerboseLogging: observable,
      setSelectedAnchor: observable,
      setSelectedLinkAnchor: observable,
      deleteSelectedLinkAnchor: action,
      setSelectedOperation: action,
      setAnnotationColor: action,
      setIsOffline: action,
      interactionModal: observable,
      openInteractionModal: action,
      closeInteractionModal: action,
      addUserModalIsOpen: observable,
      openAddUserModal: action,
      closeAddUserModal: action,
      transformInteractionMenu: observable,
      openTransformInteractionMenu: action,
      closeTransformInteractionMenu: action,
      overlappingInteractionMenu: observable,
      openOverlappingInteractionMenu: action,
      closeOverlappingInteractionMenu: action,
      selectInteraction: action,
      deviceWidth: observable,
      setDeviceWidth: action,
      pageMargin: observable,
      width: observable,

      cookieConsent: observable,
      setCookieConsent: action,

      selectionStartRange: observable,
      selectionEndRange: observable,
      setSelectionStartRange: action,
      setSelectionEndRange: action,

      isPinchZooming: observable,
      setIsPinchZooming: action,
      marginaliaIconRadius: observable,

      urlParams: observable,
      setUrlParams: action,

      folderIsOpen: observable,
      setFolderIsOpen: action,

      editedMessageId: observable,
      setEditedMessageId: action,

      forceUpdate: observable,
      forceViewerUpdate: action,

      getThumbContainerWidth: observable,

      getPodScrollPos: action,
      setPodScrollPos: action,
      activeConversations: observable,
      getActiveConversation: observable,
      setActiveConversation: action,

      timer: observable,
      incrementTimer: action,
      activePodTab: observable,
      getActivePodTab: observable,
      setActivePodTab: action,

      isFreehandSelection: observable,
      setIsFreehandSelection: action,
      freehandBox: observable,
      setFreehandBox: action,

      instantUserPad: observable,
      updateInstantUserPad: action,
      instantUserList: observable,
      generateInstantUserList: action,
      updateInstantUserPassword: action,
      deleteInstantUserList: action,

      hideInteractions: observable,
      setHideInteraction: action,

      showUnreads: observable,
      setShowUnreads: action,

      sidebarStatus: observable,
      setSidebarStatus: action,
      activeSidebarTags: observable,
      toggleActiveSidebarTag: action,
      clearActiveSidebarTags: action,

      pointerDownStart: observable,
      setPointerDownStart: action,
      activePointer: observable,
      addActivePointer: action,
      addActivePointerRect: action,
      removeActivePointer: action,

      touchList: observable,
      addToTouchList: action,
      removeFromTouchList: action,
      clearTouchList: action
    })
  }

  getThumbContainerWidth() {
    if(this.deviceWidth === "large") return 200
    if(this.deviceWidth === "medium") return 100
    else return 60
  }

  setUrlParams(key:string, value: string = '') {
    if (value) this.urlParams[key] = value; else delete this.urlParams[key]
  }

  getSelectedOperation() {
    return this.selectedInteractionType
  }

  getInteractionColor(op: OpCode | InteractionType) {
    if(op === "addAnnotation") return this.selectedAnnotationColor
    if(op === "annotation") return '#ffff00'
    if(op === "emotion" || op === "addEmotion") return "#ffd800"
    if(op === "comment" || op === "addComment") return "#ffd800"
    if(op === "link" || op === "addLink") return "#0b84fa"
    if(op === "weblink" || op === "addWeblink") return "#0b84fa"
    if(op === "tagging" || op === "addTagging") return "#7400bf"
    if(op === "readingQuestion" || op === "addReadingQuestion") return "#ec923b"
    console.warn('unexpected operation in getInteractionColor', op)
    return "gray"
  }

  getToolRepresentation(interactionType: InteractionType, tool: InteractionToolLiteral) {
    if (interactionType === 'annotation' || tool === 'box') return 'block'
    return 'underline'
  }

  getSemiTransparentColor(color : string) {
    // deal with color specifications that are not #xxxxxx
    if (color.length !== 7) return '#ff000080'
    if (color.substring(0,1) !== '#') return '#ff000080'

    // for all valid color designations, return a transparent version
    const semiTransparentColor = `${color}${Math.floor(this.overlayColorAlpha * 255).toString(16).padStart(2, '0')}`
    return semiTransparentColor
  }

  getAnnotationColor() {
    return this.selectedAnnotationColor
  }

  setSelectedAnchor(anchor: interactionAnchor | null) {
    this.selectedAnchor = anchor
  }

  setSelectedLinkAnchor(anchor: interactionAnchor) {
    this.selectedLinkAnchor = anchor
  }

  deleteSelectedLinkAnchor() {
    this.selectedLinkAnchor = null
  }

  setSelectedOperation(interactionType: OpCode | null) {
    this.selectedInteractionType = interactionType
  }

  setAnnotationColor(color: string) {
    this.selectedAnnotationColor = color
  }

  setIsOffline(isOffline: boolean) {
      // switch from online to offline mode
      // if(!this.isOffline && isOffline) {
      //     alertStore.push(alert(i18next.t('changes will be saved locally until the next time you go online again'), 'info', i18next.t('You are in Offline-Mode') ?? '', 'WifiOffIcon'))
      // }
      // // switch from offline to online mode
      // if(this.isOffline && !isOffline) {
      //     alertStore.push(alert(i18next.t('your local changes are now synchronized with the server'), 'success', i18next.t('You are online again') ?? '', 'WifiOnIcon'))
      // }
      this.isOffline = isOffline
  }

  openInteractionModal(interactionId: string, isTransformation: boolean) {
    if(interactionId) {
      this.interactionModal = {isOpen: true, interactionId: interactionId, isTransformation: isTransformation}
    }
  }

  closeInteractionModal() {
    this.interactionModal = {isOpen: false, interactionId: null, isTransformation: null}
  }

  openAddUserModal() {
    this.addUserModalIsOpen = true
  }

  closeAddUserModal() {
    this.addUserModalIsOpen = false
  }

  openTransformInteractionMenu(interactionId: string, anchor: any) {
    if(interactionId && anchor) {
      this.transformInteractionMenu = {isOpen: true, interactionId: interactionId, anchor: anchor}
    }
  }

  closeTransformInteractionMenu() {
    this.transformInteractionMenu = {isOpen: false, interactionId: null, anchor: null}
  }

  openOverlappingInteractionMenu(interactionList: {interaction: Interaction, menuAnchor: any}[]) {
    if(Array.isArray(interactionList) && interactionList.length) {
      this.overlappingInteractionMenu = {isOpen: true, interactionList: interactionList}
    }
  }

  closeOverlappingInteractionMenu() {
    this.overlappingInteractionMenu = {isOpen: false, interactionList: null}
  }

  setDeviceWidth(width: number) {
    if(width <= 600 && this.deviceWidth !== "tiny") {
      this.deviceWidth = "tiny"
      this.pageMargin = 30
    }
    if(width > 600 && width <= 750 && this.deviceWidth !== "smaller") {
      this.deviceWidth = "smaller"
      this.pageMargin = 30
    }
    if(width > 750 && width <= 950 && this.deviceWidth !== "small") {
      this.deviceWidth = "small"
      this.pageMargin = 70
    }
    if(width > 950 && width <= 1600 && this.deviceWidth !== "medium") {
      this.deviceWidth = "medium"
      this.pageMargin = 120
    }
    if(width > 1600 && this.deviceWidth !== "large") {
      this.deviceWidth = "large"
      this.pageMargin = 150
    }
    this.width = width
  }

  setCookieConsent(consent:boolean) {
    this.cookieConsent = consent
  }

  setSelectionStartRange(selectionRange:SelectionRange|null) {
    this.selectionStartRange = selectionRange
  }

  setSelectionEndRange(selectionRange:SelectionRange|null) {
    this.selectionEndRange = selectionRange
  }

  setIsPinchZooming(isPinchZooming:boolean) {
    this.isPinchZooming = isPinchZooming
  }

  setFolderIsOpen(podId:string, folderId:string, status: boolean) {
    if (typeof this.folderIsOpen[podId] === 'undefined') this.folderIsOpen[podId] = {}
    this.folderIsOpen[podId][folderId] = status
  }

  setEditedMessageId(messageId: string | null) {
    this.editedMessageId = messageId
  }

  forceViewerUpdate() {
    this.forceUpdate++
  }

  getPodScrollPos(podId: string, tab: string) {
    const podScrollPos = this.podScrollPos.find(item => item.id === podId)
    if(podScrollPos) {
      if(tab === "contents") return podScrollPos.contents
      if(tab === "activity") return podScrollPos.activity
      if(tab === "notes") return podScrollPos.notes
      if(tab === "people") return podScrollPos.people
      if(tab === "settings") return podScrollPos.settings
      if(tab === "conversations") return podScrollPos.conversations
    }
    return null
  }

  setPodScrollPos(podId: string, tab: string, pos: number) {
    const podScrollPos = this.podScrollPos.find(item => item.id === podId)
    if(podScrollPos) {
      if(tab === "contents") podScrollPos.contents = pos
      if(tab === "activity") podScrollPos.activity = pos
      if(tab === "notes") podScrollPos.notes = pos
      if(tab === "people") podScrollPos.people = pos
      if(tab === "settings") podScrollPos.settings = pos
      if(tab === "conversations") podScrollPos.conversations = pos
    } else {
      // init scrollPos
      const scrollPos: PodScrollPos = {
        id: podId,
        contents: (tab === "contents") ? pos : 0,
        activity: (tab === "activity") ? pos : 0,
        notes: (tab === "notes") ? pos : 0,
        people: (tab === "people") ? pos : 0,
        settings: (tab === "settings") ? pos : 0,
        conversations: (tab === "conversations") ? pos : 0,
      }
      this.podScrollPos.push(scrollPos)
    }
  }

  getActiveConversation(podId: string) {
    const activeConversation = this.activeConversations[podId]
    if(activeConversation) return activeConversation
    return null
  }

  setActiveConversation(podId: string, activeConversation: string | null) {
    this.activeConversations[podId] = activeConversation
  }

  incrementTimer() {
    this.timer++
  }

  getActivePodTab(podId: string) {
    const activePodTab = this.activePodTab[podId]
    if(activePodTab) return activePodTab
    return "contents"
  }

  setActivePodTab(podId: string, activeTab: string) {
    this.activePodTab[podId] = activeTab
  }

  setPreviewColor(interactionId: string, color: string) {
    if(interactionId && color) this.previewColor = {color: color, interactionId: interactionId}
  }
  deletePreviewColor() {
    this.previewColor = null
  }

  getPreviewLinkLabel(interactionId: string) {
    const linkLabel = this.previewLinkLabel[interactionId]
    if(linkLabel || linkLabel === '') return linkLabel
    return null
  }
  setPreviewLinkLabel(interactionId: string, linkLabel: string) {
    if(interactionId) {
      this.previewLinkLabel[interactionId] = linkLabel
    }
  }
  deletePreviewLinkLabel(interactionId: string) {
    if(this.previewLinkLabel[interactionId] || this.previewLinkLabel[interactionId] === "") {
      delete this.previewLinkLabel[interactionId]
    }
  }

  setIsFreehandSelection(isFreehandSelection: boolean) {
    this.isFreehandSelection = isFreehandSelection
  }

  setFreehandBox(page: number|null = null, x0: number|null = null, y0: number|null = null, x1: number|null = null, y1: number|null = null) {
    if (page===null || x0 === null || x1 === null || y0 === null || y1 === null) {
      this.freehandBox = null
    }
    else {
      // test if selection ist outside page
      if (this.freehandBox && this.freehandBox.page !== page) {
        alertStore.push(alert(t('Freehand selections beyond one side are unfortunately not possible'), 'warning', ''))
        this.freehandBox = null
      }
      else {
        this.freehandBox = {
          page, x0, y0, x1, y1
        }
      }
    }
  }

  setHideInteraction(interaction:VisibilitySelectable, value:boolean) {
    if (value) this.hideInteractions[interaction] = true; else delete this.hideInteractions[interaction]
  }

  restrictString(s:string, type?:'name'|'filename'|'foldername'|'tag'|'username', length?:number|null) {
    switch(type) {
      case 'filename':
      case 'name':
      case 'tag':
        s = s.replace(/ /g, '_')
        s = s.replace(/[#>@]/g, '_')
        if (type === 'filename') s = s.substring(0, 48)
        break

      case 'username':
        s = s.replace(notUserNameRegex, '_')
        s = s.substring(0, usernameMaxLength)
        break

      case 'foldername':
        s = s.substring(0, 48)
        break
    }
    if (length !== null) s = s.substring(0, length)
    return s
  }

  updateInstantUserPad = (userPad: string) => {
    this.instantUserPad = userPad
  }

  generateInstantUserList(userList: string[]) {
    if(userList.length && podStore.pod) {
      const list: Array<{id: number, login: string, password: string, username: string}> = []

      // generate list of used logins
      const usedLogins: string[] = []
      const userInfos = podStore.pod.userInfos
      for(const id in userInfos) {
        const userInfo = userInfos[id]
        if(userInfo.login) usedLogins.push(userInfo.login)
      }

      // generate user credentials
      userList.forEach((user, index) => {
        const login = generateUniqueLogin(user, usedLogins)
        const password = generateInstantPassword()
        if(user && login && password) {
          list.push({id: index, login: login, password: password, username: user})
          usedLogins.push(login)
        }
      })
      this.instantUserList = list
    }
  }

  updateInstantUserPassword(id: number, password: string) {
    if(this.instantUserList.length && password !== undefined) {
      const user = this.instantUserList.find(user => user.id === id)
      if(user) {
        user.password = password
      }
    }
  }

  deleteInstantUserList() {
    this.instantUserList = []
  }

  setShowUnreads(showUnreads:boolean) {
    this.showUnreads = showUnreads
  }

  setSidebarStatus(sidebarStatus: string) {
    this.sidebarStatus = sidebarStatus
  }

  toggleActiveSidebarTag = (tag: string) => {
    if(this.activeSidebarTags.includes(tag)) {
      this.activeSidebarTags = this.activeSidebarTags.filter(activeTag => activeTag !== tag)
    } else {
      this.activeSidebarTags.push(tag)
    }
  }
  clearActiveSidebarTags = () => {
    this.activeSidebarTags = []
  }

  selectInteraction(selected: {interaction:  Interaction, menuAnchor: any}, podId: string, pdfId: string) {
    if(selected && selected.interaction && selected.menuAnchor) {
      const pdfUrl = `/pod/${podId}/pdf/${pdfId}`
      const interaction = selected.interaction
      const interactionId = interaction.interactionId
      const interactionType = interaction.interactionType
      const menuAnchor = selected.menuAnchor
      // if selection is not yet an interaction, open the menu to transform it into an interaction
      //(annotations without a label are not yet interactions in this sense)
      if(interactionType === "annotation" && interaction.label === "") {
        this.openTransformInteractionMenu(interactionId, menuAnchor)
      } else {
        if(interactionType === "annotation") navigate(`${pdfUrl}/annotation/${interactionId}`, {replace: true})
        if(interactionType === "comment") navigate(`${pdfUrl}/comment/${interactionId}`, {replace: true})
        if(interactionType === "link") navigate(`${pdfUrl}/link/${interactionId}`, {replace: true})
        if(interactionType === "tagging") navigate(`${pdfUrl}/tag/${interactionId}`, {replace: true})
        if(interactionType === "weblink") navigate(`${pdfUrl}/weblink/${interactionId}`, {replace: true})
        if(interactionType === "emotion") navigate(`${pdfUrl}/emotion/${interactionId}`, {replace: true})
        if(interactionType === "readingQuestion") navigate(`${pdfUrl}/readingQuestion/${interactionId}`, {replace: true})
      }
    }
  }

  setPointerDownStart(pointerDownStart: number | null) {
    this.pointerDownStart = pointerDownStart
  }

  addActivePointer(pointerId: number, pointerType: 'mouse'|'pen'|'touch', storeId: string, x: number, y: number) {
    if (Number.isInteger(pointerId) && (pointerType === 'mouse' || pointerType === 'pen' || pointerType === 'touch') && storeId) {
      this.activePointer[pointerId] = {storeId: storeId, pointerType: pointerType, lastX: x, lastY: y, rectArray: []}
    } else {
      console.warn('addActivePointer: variables do not match the expected inputs')
    }
  }

  addActivePointerRect(pointerId: number, rect: Rect) {
    if(Number.isInteger(pointerId) && this.activePointer[pointerId] && rect) {
      this.activePointer[pointerId].rectArray.push(rect)
    }
  }

  updateActivePointerPosition(pointerId: number, x: number, y: number) {
    if(Number.isInteger(pointerId) && this.activePointer[pointerId]) {
      this.activePointer[pointerId].lastX = x
      this.activePointer[pointerId].lastY = y
    }
  }

  removeActivePointer(pointerId: number) {
    if(Number.isInteger(pointerId) && this.activePointer[pointerId]) {
      delete this.activePointer[pointerId]
    }
  }

  addToTouchList(touchId: number, x: number, y: number) {
    const touchItem = this.touchList[touchId]

    if(touchItem) {
      touchItem.x = x
      touchItem.y = y
    }
    else this.touchList[touchId] = {x: x, y: y}
  }
  removeFromTouchList(touchId: number) {
    if(this.touchList[touchId]) delete this.touchList[touchId]
  }
  clearTouchList() {
    this.touchList = {}
  }
}


const exportUiStore = new uiStore()
export default exportUiStore


function generateUniqueLogin(username: string, usedLogins: string[]) {
  // replace blank characters
  const user: string = username.replaceAll(' ','_')
  // convert Umlauts, Accents, Special characters
  const convertedName = convertNames(user)
  // make login case insensitive
  const lowerCaseUser = convertedName.toLowerCase()
  // remove invalid characters
  let login = lowerCaseUser.replace(notInstantLoginRegex, '')
  // if the replacements have enlarged the login name too much, shorten the name
  if(login.length > usernameMaxLength) {
    login = login.substring(0, usernameMaxLength)
  }
  // search for unique login name
  if(login) {
    // check if login is unique
    // else: add enumerating number
    const isUnique = isUniqueLogin(login, usedLogins)
    if(isUnique) return login
    else {
      let enumerating = 1
      while(true) {
        const uniqueLogin = `${login}${enumerating}`
        if(isUniqueLogin(uniqueLogin, usedLogins)) return uniqueLogin
        // test for next number
        enumerating++
        // break after 1000 repeats, prevent loop
        if(enumerating > 1000) return null
      }
    }
  }
  return null
}

function isUniqueLogin(login: string, usedLogins: string[]) {
  for(const usedLogin of usedLogins) {
    if(login === usedLogin) return false
  }
  return true
}

export function generateInstantPassword() {
  const crypt = window.crypto.getRandomValues(new BigUint64Array(1))[0].toString(36)
  const instantPassword = `${crypt.slice(0,4)}-${crypt.slice(4,8)}`
  return instantPassword
}
